import { Resolve, Router } from '@angular/router';
import { RequestService } from '@proman/services/request.service';
import { Inject, Injectable } from '@angular/core';
import { PreferencesService } from '@proman/services/preferences.service';
import { ACL } from '@proman/services/acl.service';
import { WebsocketService } from '@proman/services/websocket.service';
import { AuthService } from '@proman/services/auth.service';
import { Location } from '@angular/common';
import { AppInitService } from '@proman/services/app-init.service';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { COOKIE_AUTHORIZATION, CookiesService } from '@proman/services/cookies.service';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { Entity } from '@proman/services/entity.service';
import {
    UI_HIDE_WARNINGS,
    UI_TABLE_SAVED_FILTERS,
    UiPreferencesService
} from '@proman/services/ui-preferences.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { isDefined, isDefinedNotNull, isLocalhost } from '@proman/utils';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { filter, take } from '@proman/rxjs-common';
import { setDocsIdData, DocsIdValues } from '@proman/store/docs-id';
import { setCurrUser } from '@proman/store/curr-user';
import { getSystemOptions, loadSystemOptions } from '@proman/store/system-options';
import { getCurrencies, loadCurrency } from '@proman/store/currency';
import { forkJoin } from 'rxjs';
import { CONFIG } from '@proman/config';
import { FRONTEND_ENTITY_TABLE_UI_PREFS_KEY } from './constants';

@Injectable()
export class UserResolver implements Resolve<any> {

    constructor(
        @Inject(RequestService) private Request: RequestService,
        @Inject(PreferencesService) private Prefs: PreferencesService,
        @Inject(Router) private Router: Router,
        @Inject(ACL) private ACL: ACL,
        @Inject(Entity) private Entity: Entity,
        @Inject(Location) private Location: Location,
        @Inject(AuthService) private Auth: AuthService,
        @Inject(WebsocketService) private Websocket: WebsocketService,
        @Inject(AppInitService) private AppInit: AppInitService,
        @Inject(PromanStateService) private PromanState: PromanStateService,
        @Inject(CookiesService) private Cookies: CookiesService,
        @Inject(LanguageConfigService) private LangConfig: LanguageConfigService,
        @Inject(UiPreferencesService) private UiPrefs: UiPreferencesService,
        @Inject(Store) private store: Store,
    ) {

    }

    private loadUser(): Promise<CurrUser> {
        return new Promise((resolve) => {
            this.store.select(getCurrUser)
                .pipe(take(1))
                .subscribe((value) => {
                    if (this.isValidUser(value)) {
                        resolve(value);
                    } else {
                        resolve(this.Request.loadUser());
                    }
                });
        });
    }

    isValidUser = (userData: CurrUser) => {
        return !!userData && (userData.isEmployee && userData.person || userData.isCustomer && userData.person || userData.isAgent && userData.person);
    };

    resolve(): Promise<any> {
        const bareToken = this.Cookies.get(COOKIE_AUTHORIZATION);

        return new Promise((resolve) => {

            forkJoin([
                this.store.select(getCurrUser)
                    .pipe(take(1)),
                this.store.select(getSystemOptions)
                    .pipe(take(1)),
            ])

                .subscribe(([currUser, systemOptions]) => {
                    if (!!currUser && this.isValidUser(currUser) && !!systemOptions.id) {
                        resolve(currUser);

                    } else {
                        this.loadUser()
                            .then(async (response: CurrUser) => {
                                if (!response) {
                                    return;
                                }

                                if (!isLocalhost() && isDefinedNotNull(response.allowFrontend) && response.allowFrontend === false) {
                                    window.location.replace(`${CONFIG.root}shop`);
                                    return;
                                }

                                this.Cookies.set(COOKIE_AUTHORIZATION, bareToken);

                                if (response.language !== this.AppInit.currentLang) {
                                    this.Prefs.language(response.language);
                                    await this.LangConfig.loadLanguage(response.language);

                                }

                                this.store.dispatch(setCurrUser({ payload: response }));
                                this.store.select(getCurrUser)
                                    .pipe(filter((value) => !!value), take(1))
                                    .subscribe(() => this.Websocket.startSession());


                                this.store.dispatch(loadSystemOptions());
                                this.store.dispatch(loadCurrency());
                                await new Promise<void>((resolve2) => {
                                    this.store.select(getSystemOptions)
                                        .pipe(
                                            filter((systemOptions) => !!systemOptions.id),
                                            take(1),
                                        )
                                       .subscribe(() => resolve2());
                                });
                                await new Promise<void>((resolve3) => {
                                    this.store.select(getCurrencies)
                                        .pipe(
                                            filter((currencies) => !!currencies && !!currencies.length),
                                            take(1),
                                        )
                                       .subscribe(() => resolve3());
                                });
                                const tableSettings = response.person.tableSettings || {};

                                const handleJsonData = (data: any) => {
                                    if (data) {
                                        return JSON.parse(
                                            JSON.stringify(data)
                                                .split('"false"')
                                                .join('false')
                                                .split('"true"')
                                                .join('true')
                                        );
                                    }

                                };

                                this.UiPrefs.set(UI_TABLE_SAVED_FILTERS, handleJsonData(tableSettings.filters || {}));
                                this.UiPrefs.set(FRONTEND_ENTITY_TABLE_UI_PREFS_KEY, handleJsonData(tableSettings.settings || {}));

                                const settings = handleJsonData(response.person.settings);

                                if (settings && isDefined(settings[UI_HIDE_WARNINGS])) {
                                    this.UiPrefs.set(UI_HIDE_WARNINGS, settings[UI_HIDE_WARNINGS]);
                                }

                                this.Request
                                    .get(`https://doc.proman.app/public/upload/summary/doc-summary-${response.language}.json`)
                                    .then((res: {
                                        collection: DocsIdValues[];
                                        head: { locale: string; time: string };
                                    }) => {
                                        const payload = res.collection.reduce((a, b) => {
                                            a[b.title] = b;
                                            return a;
                                        }, {});

                                        this.store.dispatch(setDocsIdData({ payload }));
                                    })
                                    .catch(() => {});

                                resolve(response);
                            })
                            .catch((error) => {
                                this.Router.navigate(['login']);
                            });
                    }
                });
             });
    }
}
